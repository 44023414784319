.ConsultationDocuments {
  min-height: 80vh;
  margin-top: 20px;
}

.ConsultationDocuments h1 {
  margin-bottom: 20px;
}

.card-body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}
