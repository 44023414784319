:root {
  --aiex-yellow: #F1EC41;
  --aiex-grey: #F2F4F4;
  --aiex-white: #FFFFFF;
  --aiex-dark: #030606;
}

.scopes-container {
  background-color: var(--aiex-grey);
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 8px;
}

.scopes-slider-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
}

.scopes-conten {
  display: flex;
}

.slider-scope {
  display: flex;
  width: 400px;
  height: 400px;
  overflow: hidden;
}

.scopesSlider__imageContainer {
  width: 400px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 500ms ease-in-out;
}

.scopes-slider-title h2{
  text-shadow: 4px 4px 6px var(--aiex-yellow);
}