:root {
  --aiex-yellow: #F1EC41;
  --aiex-grey: #F2F4F4;
  --aiex-white: #FFFFFF;
  --aiex-dark: #030606;
}

.our-allies-container {
  min-height: 705px;
}

.our-allies-content {
  height: 100%;
}

.our-allies-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.our-allies-left img {
  height: 380px;
  border-bottom: 4px solid var(--aiex-dark);
  border-radius: 8px;
}

.our-allies-left .animate {
  animation: fadeIn 3s ease;
}

.our-allies-slider {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.our-allies-slider-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  min-height: calc(3 * 230px);
  width: 380px;
  border: 1.5px solid var(--aiex-yellow);
  border-radius: 8px;
  box-shadow: 4px 4px 6px var(--aiex-yellow);
}

.slider {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s ease;
}

.nav-btn-QA {
  border-radius: 50px;
  border: none;
  background-color: transparent;
  z-index: 150;
  cursor: pointer;
  width: 100%;
  font-size: 30px;
}