:root {
  --aiex-yellow: #F1EC41;
  --aiex-grey: #F2F4F4;
  --aiex-white: #FFFFFF;
  --aiex-dark: #030606;
}

.navBar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 120px;
  border-bottom: 1.5px solid var(--aiex-yellow);
  box-shadow: 0px 6px 6px var(--aiex-yellow);
  z-index: 100;
}

.navBarIzq {
  display: flex;
}

.onac-logo-navbar img,
.aiex-logo-navbar img{
  cursor: pointer;
}

.aiex-logo-navbar img{
  height: 80px;
}

.onac-logo-navbar img{
  height: 95px;
}

.navBar__links {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.navBar__links a {
  text-decoration: none;
  color: var(--aiex-dark);
  font-size: 17px;
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navBar__links a:hover {
  background-color: var(--aiex-yellow);
  font-weight: bold;
}

.btnForm {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnForm button{
  width: 150px;
  height: 44px;
  border-radius: 12px;
  border: none;
  box-shadow: 4px 4px 12px var(--aiex-dark);
  background-color: var(--aiex-yellow);
  color: var(--aiex-dark);
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.btnForm button:hover {
  color: var(--aiex-white);
  background-color: var(--aiex-dark);
}

.container-iframe-navbar {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.container-iframe-navbar .container-form-registro {
  width: 100%;
  height: 100%;
}