:root {
  --aiex-yellow: #F1EC41;
  --aiex-grey: #F2F4F4;
  --aiex-white: #FFFFFF;
  --aiex-dark: #030606;
}

.policies-container {
  min-height: 130px;
  padding: 8px;
  background: rgb(3,6,6);
  background: linear-gradient(90deg, var(--aiex-dark) 67%, var(--aiex-grey) 99%, var(--aiex-white) 100%);
  color: var(--aiex-white);
  justify-content: center;
  align-items: center;
}

.policie-info-left,
.policie-info-rigth {
  margin-left: 20px;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.policie-info-left h2,
.policie-info-rigth h2 {
  font-size: 24px;
}

.policie-info-rigth button {
  width: 180px;
  background-color: var(--aiex-yellow);
  font-size: 12px;
  font-weight: bold;
  height: 32px;
  border-radius: 12px;
  border: none;
  padding: 4px;
  cursor: pointer;
}

.policie-info-left p {
  margin-left: 0;
  padding-left: 0;
}