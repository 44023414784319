.manuals-content {
  min-height: 80vh;
}

.video-container {
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container .react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
