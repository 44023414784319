:root {
  --aiex-yellow: #F1EC41;
  --aiex-grey: #F2F4F4;
  --aiex-white: #FFFFFF;
  --aiex-dark: #030606;
}

.homeAboutUs {
  width: 100%;
  min-height: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.body-about {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  min-height: 550px;
  position: relative;
}

.cont-about-img {
  width: 50%;
}

.cont-about-img img {
  width: 400px;
}

.cont-about-img.animate img {
  animation: fadeIn 4.2s forwards;
}

.cont-about-text {
  width: 50%;
  height: 450px;
  position: relative;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-context-text {
  width: 350px;
  height: 350px;
  border-radius: 50%;
  text-align: center;
  font-size: 15px;
  margin: auto;
  background-color: var(--aiex-grey);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  position: relative;
  z-index: 120;
}

.about-context-text h3, p, button {
  margin: 0 20px;
}

.about-context-text.animate h3, .about-context-text.animate p, .about-context-text.animate button {
  animation: fadeIn 4.2s forwards;
}

.btn-saber-mas {
  background-color: var(--aiex-yellow);
  border:none;
  color: var(--aiex-dark);
  font-weight: bold;
  width: 120px;
  height: 30px;
  border-radius: 12px;
  cursor: pointer;
  position: relative;
  top: 10px;
}

.about-circle-1, .about-circle-2, .about-circle-3 {
  position: absolute;
  border-radius: 50%;
}

.about-circle-1 {
  background-color: var(--aiex-dark);
  width: 300px;
  height: 300px;
  opacity: 0;
  left: 5%;
  top: 5%;
}

.about-circle-1.animate {
  animation: aboutCircle1 3s forwards cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.about-circle-2 {
  background-color: var(--aiex-yellow);
  width: 300px;
  height: 300px;
  opacity: 0;
  right: 5%;
  bottom: 5%;
}

.about-circle-2.animate {
  animation: aboutCircle2 3.5s forwards cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.about-circle-3 {
  background-color: var(--aiex-yellow);
  width: 150px;
  height: 150px;
  opacity: 0;
  top: 3%;
  left: 15%;
}

.about-circle-3.animate {
  animation: aboutCircle3 5s forwards cubic-bezier(0.68, -0.55, 0.27, 1.55);
}




@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes aboutCircle1 {
  0% {
    width: 0;
    height: 0;
    opacity: 0.7;
  }
  50% {
    width: 150px;
    height: 150px;
    opacity: 1;
  }
  100% {
    width: 300px;
    height: 300px;
    opacity: 1;
  }
}

@keyframes aboutCircle2 {
  0% {
    width: 0;
    height: 0;
    opacity: 0.7;
  }
  50% {
    width: 150px;
    height: 150px;
    opacity: 1;
  }
  100% {
    width: 300px;
    height: 300px;
    opacity: 1;
  }
}

@keyframes aboutCircle3 {
  0% {
    width: 0;
    height: 0;
    opacity: 0.7;
  }
  50% {
    width: 75px;
    height: 75px;
    opacity: 1;
  }
  100% {
    width: 150px;
    height: 150px;
    opacity: 1;
  }
}
