:root {
  --aiex-yellow: #F1EC41;
  --aiex-grey: #F2F4F4;
  --aiex-white: #FFFFFF;
  --aiex-dark: #030606;
}

body {
  margin: 0;
}

.form-registro {
  margin: auto;
  padding: 0 8px;
  background: rgba(0, 0, 0, 0.6);
  box-sizing: border-box;
}

.registro-intro h2 {
  color: var(--aiex-white);
  text-align: center;
  font-size: 30px;
  margin-bottom: 20px; 
}

.registro-intro h3 {
  color: var(--aiex-white);
  text-align: center;
  margin: 0;
  font-size: 15px;
  margin-bottom: 20px; 
}

.registro-body-form h2 {
  color: var(--aiex-dark);
  text-align: center;
  font-size: 30px;
  margin-bottom: 20px; 
}

.registro-body-form h3 {
  color: var(--aiex-dark);
  text-align: center;
  margin: 0;
  font-size: 15px;
  margin-bottom: 20px; 
}

.contrato {
  width: 100%;
  height: 250px;
  border-radius: 8px;
  margin: 0 4px;
}

#titulo1 {
  text-align: center;
  font-size: 20px;
}

#titulo2 {
  text-align: left;
}


input, textarea, select {
  width: 100%;
  margin-bottom: 10px;
  padding: 7px;
  box-sizing: border-box;
  font-size: 15px;
  border: none;
}


#acepto, #leido {
  width: 5%;
}


#contrato {
  resize: none;
  text-align: justify;
  white-space: normal;
}

.codigo-label {
  color: red;
}

.cont-text-el-solicitante p {
  color: var(--aiex-white);
  text-align: justify;
  white-space: normal;  
  font-size: 9.5px;
}

.cont-text-form-reg p {
  color: var(--aiex-white);
  text-align: justify;
  white-space: normal;  
  font-size: 14px;
}

.cont-text-form-reg-body p {
  color: var(--aiex-dark);
  text-align: justify;
  white-space: normal;  
  font-size: 14px;
}

.form-check label {
  color: var(--aiex-white);
}

.logo-tabla-form{
  width: 80px;
  height: 80px;
}

.codigo-label-version {
  color: var(--aiex-white);
}

.codigo-label-version-body {
  color: var(--aiex-dark);
}

.cont-form-reg-iframe {
  height: 90vh;
}

.d-none {
  display: none;
}

#error {
  background-color:lightcoral;
  color:black;
  text-align: center;
  font-size: 15px;
}

#btnenviar {
  background: #4f69ac;
  color: #fff;
  padding: 15px;
  border-radius: 10px;
}

#btnenviar:hover {
  cursor: pointer;
}
