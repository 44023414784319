:root {
  --aiex-yellow: #F1EC41;
  --aiex-grey: #F2F4F4;
  --aiex-white: #FFFFFF;
  --aiex-dark: #030606;
}

.beginning-slider {
  width: 100%;
  height: 500px;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.beginning__title h2 {
  color: var(--aiex-yellow);
  font-size: 30px;
  font-weight: bold;
  text-shadow: 4px 4px 4px var(--aiex-dark);
}

.cont-slider-b {
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  display: flex;
  gap: 16px;
}

.slider-img.left-b,
.slider-img.right-b,
.slider-img.focus-b {
  opacity: 1;
  display: block;
  border-radius: 25px;
  height: 320px;
  width: 320px;
}

/* Media Queries */
@media (max-width: 1024px) {
  .slider-img.right-b {
    display: none;
  }
}

@media (max-width: 700px) {
  .slider-img.left-b {
    display: none;
  }
}